<template>
    <v-simple-table class="elevation-0 transparent">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="(header, idx) in headers" :key="idx" :class="header.align">
              {{ header.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, idx) in products" :key="idx">
            <td style="width: 50px">
              <v-avatar size="40" rounded class="elevation-3 my-3" style="transition: all .2s">
                <v-img v-if="product.image" :src="$store.state.imagePath + product.image" />
                <v-icon v-else small color="primary">fas fa-box</v-icon>
              </v-avatar>
            </td>
            <td>{{ product.reference }}</td>
            <td>{{ product.name }}</td>
            <td class="text-center">
              <ul>
                  <li v-for="(item, idx) in product_volumes(product.volumes)" :key="idx">
                      <div class="caption">{{ item.volume + ' x ' + item.type}}</div>
                      <span class="caption grey--text">{{ 'L-' + item.width + ' x ' + 'A-' + item.height + ' x ' + 'C-' + item.volume_length  }}</span>
                  </li>
              </ul>
            </td>
            <td class="text-center">{{ product.weight ? product.weight + ' kg' : '-' }}</td>
            <td class="text-center">{{ getToInvoiceQtty(product.invoiced_qtty, product.produced_qtty) }}</td>
            <td class="text-right">
              {{ unitaryCost(product) }}
            </td>
            <td class="text-right">
              {{ subtotal(product) }}
            </td>
            <td width="150" class="text-center">
                <v-text-field
                    v-if="isInvoiced == false"
                    v-model.number="product.to_invoice_qtty"
                    label="Qtd."
                    type="number"
                    :min="1"
                    :max="product.produced_qtty"
                    dense
                    hide-details
                    rounded
                    outlined
                />
                <v-chip v-else class="success darken-2">
                    <span>Faturado: <strong>{{ product.done_quantity }}</strong></span>
                </v-chip>
            </td>
            <td class="text-right" width="50">
                <v-btn v-if="isInvoiced == false" @click="$emit('removeReceiptProduct', idx)" icon color="error" small>
                    <v-icon small>fas fa-times</v-icon>
                </v-btn>
            </td>
          </tr>
          <tr class="grey lighten-3 secondary--text">
              <td colspan="3"></td>
              <td class="text-center font-weight-bold py-2">
                  <div v-if="totalVolumes[0] > 0">
                      Caixas: {{ totalVolumes[0] }}
                  </div>
                  <div v-if="totalVolumes[1] > 0">
                      Pacotes: {{ totalVolumes[1] }}
                  </div>
                  <div v-if="totalVolumes[2] > 0">
                      Rolos: {{ totalVolumes[2] }}
                  </div>
                  <div v-if="totalVolumes[3] > 0">
                      Sacos: {{ totalVolumes[3] }}
                  </div>
                  <div v-if="totalVolumes[4] > 0">
                      Envelopes: {{ totalVolumes[4] }}
                  </div>
                  <div v-if="totalVolumes[5] > 0">
                      Palletes: {{ totalVolumes[5] }}
                  </div>
              </td>
              <td class="text-center font-weight-bold">{{ totalWeight }} kg</td>
              <td colspan="1"></td>
              <td class="text-right font-weight-bold">
                {{ totalValue ? totalValue : NFTotal }}
              </td>
              <td colspan="2"></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </template>
  
  <script>
  export default {
    props: {
      products: Array,
      buttonText: String,
      tableHeader: Array,
      totalValue: {
        type: [Number, String],
      },
      isInvoiced: {
        type: [Boolean, Number],
        default: () => false
      },
      isExpanded: Boolean
    },
    data: () => ({
      quantityValue: [],
      to_invoice_qtty: null,
      headers: [
        { title: 'Imagem', align: "" },
        { title: 'Código', align: "" },
        { title: 'Nome', align: "" },
        { title: 'Volumes', align: "text-center" },
        { title: 'Peso (Kg)', align: "text-center" },
        { title: 'A faturar', align: "text-center" },
        { title: 'Preço UN', align: "text-right" },
        { title: 'Subtotal', align: "text-right" },
        { title: 'Qtd. Faturar', align: "text-center" },
        { title: '', align: "text-right" },
      ]
    }),
    computed: {
        NFTotal(){
            var total = 0

            this.products.map(prod => {
                let un = prod.alt_unitary ? parseFloat(prod.alt_unitary.replace(',', '.')) : prod.default_unitary 
                total = total + (prod.to_invoice_qtty * un)
            })

            if(!total){
                return 'R$ 0,00'
            }

            return parseFloat(total.toFixed(2)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
      totalWeight(){
          let total = 0
          this.products.map(prod => {
              total = total + prod.weight
          })
  
          return total
      },
      totalVolumes(){
          let volumes = [0,0,0,0,0,0]
  
          this.products.map(prod => {
              prod.volumes.map(vol => {
                  switch (vol.type) {
                      case 1:
                          volumes[0] = volumes[0] + parseInt(vol.volume)
                          break;  
                      case 2:
                          volumes[1] = volumes[1] + parseInt(vol.volume)
                          break;  
                      case 3:
                          volumes[2] = volumes[2] + parseInt(vol.volume)
                          break;  
                      case 4:
                          volumes[3] = volumes[3] + parseInt(vol.volume)
                          break;  
                      case 5:
                          volumes[4] = volumes[4] + parseInt(vol.volume)
                          break;  
                      case 6:
                          volumes[5] = volumes[5] + parseInt(vol.volume)
                          break;                
                      default:
                          break;
                  }
              })
          })
  
          return volumes
      },
    },
    watch: {
      NFTotal(newvalue){
        this.$emit('setTotalNF', newvalue)
      },
    },
    methods: {        
        getToInvoiceQtty(invoiced, produced){
            if(produced - invoiced <= 0){
                return 0
            }else{
                return produced - invoiced
            }
        },
      quantity(product_quantity, index) {
        return product_quantity + ' / ' + (parseInt(product_quantity) - parseInt(this.quantityValue[index]))
      },
      addProduct(product, quantity) {
        product.done_quantity = product.done_quantity + parseInt(quantity)
        var receiptProduct = { ...product, done_quantity: parseInt(quantity) }
        this.$emit('addProductAction', receiptProduct)
        this.quantityValue = []
      },
      unitaryCost(item){
          if(item.alt_qtty){
              if(item.alt_unitary.includes(',')){
                  let cost = item.alt_unitary.replace(',', '.')
                  cost = parseFloat(cost)
                  return cost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              }else{
                  return parseFloat(item.alt_unitary).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              }
          }else{
              return parseFloat(item.default_unitary).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          }
      },
      subtotal(item){
        if(item.done_quantity){
            if(item.alt_unitary){
                if(item.alt_unitary.includes(',')){
                    let cost = item.alt_unitary.replace(',', '.')
                    let subtotal = 0
    
                    cost = parseFloat(cost)
                    subtotal = cost * item.done_quantity
                    return subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                }else{
                    return parseFloat(item.alt_unitary * item.done_quantity).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                }
            }else{
                return parseFloat(item.default_unitary * item.done_quantity).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            }
        }
        
        if(!item.to_invoice_qtty){
            return 'R$ 0,00'
        }

        if(item.alt_unitary){
            if(item.alt_unitary.includes(',')){
                let cost = item.alt_unitary.replace(',', '.')
                let subtotal = 0

                cost = parseFloat(cost)
                subtotal = cost * item.to_invoice_qtty
                return subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            }else{
                return parseFloat(item.alt_unitary * item.to_invoice_qtty).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            }
        }else{
            return parseFloat(item.default_unitary * item.to_invoice_qtty).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }
      },
      getVolumeType(type){
          switch(type){
              case 1:
                  return 'Caixa'
              case 2:
                  return 'Pacote'
              case 3:
                  return 'Rolo'
              case 4:
                  return 'Saco'
              case 5:
                  return 'Envelope'
              case 6:
                  return 'Pallete'
          }
      },
      product_volumes(volumes){
          if(volumes.length == 0) return [{type: '-', volume: '-'}]
  
          let volumesArr = []
  
          volumes.map(vol => {
              let { height, width, volume_length, volume, type } = vol
              
              let volume_type = this.getVolumeType(type)
  
              volumesArr.push({ type: volume_type, height, width, volume_length, volume })
          })
  
  
          return volumesArr
      },
      checkStatus(status){
          switch (status) {
              case 41:
                  return "Aguardando compra";
              case 42:
                  return "Aguardando gravação";
              case 43:
                  return "Aguardando conferência";
              case 44:
                  return "Aguardando produção";
              case 45:
                  return "Aguardando faturamento";
              case 46:
                  return "Aguardando finalização";
              case 47:
                  return "Finalizado";
          }
          return status;
      },
      getColor(status){
          switch (status) {
              case 41:
                  return "blue-grey";
              case 42:
                  return "indigo";
              case 43:
                  return "cyan";
              case 44:
                  return "deep-purple";
              case 45:
                  return "orange lighten-2";
              case 46:
                  return "teal";
              case 47:
                  return "success";
          }
          return status;
      },
    }
  }
  </script>
  
  <style></style>